import { contactSubmit } from './contact.js';
import { supplies } from './supplies.js';

supplies.init();

if (window.NodeList && !NodeList.prototype.forEach) {
	NodeList.prototype.forEach = Array.prototype.forEach;
}

document.querySelectorAll('nav a[d_click]').forEach(i => {
	i.addEventListener('click', e => {
		if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
			if(!i.hasAttribute('clicked') && (window.innerWidth > 750 || i.hasAttribute('mobile_d_click'))) {
				e.preventDefault();
				i.setAttribute('clicked', true);
			}
		}
	});
});


if (document.getElementById('contact_form')) {
	contactSubmit();
}
